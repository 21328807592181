import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { quizService } from '../services/quizService'

export const getAllQuizzes = createAsyncThunk('quiz/getAll', async (_, { rejectWithValue }) => {
  try {
    const response = await quizService.getAll()
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getOneQuiz = createAsyncThunk(
  'quiz/getOne',
  async (data, { rejectWithValue }) => {
    try {
      const response = await quizService.getOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const createQuiz = createAsyncThunk(
  'quiz/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await quizService.createQuiz(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const editQuiz = createAsyncThunk('quiz/edit', async (data, { rejectWithValue }) => {
  try {
    const response = await quizService.editQuiz(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteQuiz = createAsyncThunk(
  'quiz/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await quizService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllQuizzes.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllQuizzes.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllQuizzes.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getOneQuiz.pending]: (state) => {
      state.loading = true
    },
    [getOneQuiz.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneQuiz.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createQuiz.pending]: (state) => {
      state.loading = true
    },
    [createQuiz.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createQuiz.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editQuiz.pending]: (state) => {
      state.loading = true
    },
    [editQuiz.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editQuiz.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteQuiz.pending]: (state) => {
      state.loading = true
    },
    [deleteQuiz.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteQuiz.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
