import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storeService } from "../services/storeService.js";

export const getAllStoreItems = createAsyncThunk(
	"store/getAll",
	async (_, { rejectWithValue }) => {
		try {
			const response = await storeService.getAllStoreItems();
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSingleStore = createAsyncThunk(
	"book/getOne",
	async (data, { rejectWithValue }) => {
		try {
			const response = await storeService.getSingleStore(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const createStoreItem = createAsyncThunk(
	"store/create",
	async (data, { rejectWithValue }) => {
		try {
			const response = await storeService.createStoreItem(data);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateStoreDetails = createAsyncThunk(
	"store/update",
	async (data, { rejectWithValue }) => {
		try {
			const response = await storeService.updateStoreDetails(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const deleteStoreItem = createAsyncThunk(
	"store/delete",
	async (data, { rejectWithValue }) => {
		try {
			const response = await storeService.deleteStoreItem(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

const initialState = {
	data: [],
	singleData: {},
	loading: false,
	error: false,
	message: "",
};

const slice = createSlice({
	name: "store",
	initialState,
	reducers: {
		checkAll: (state) => {
			state.isChecked = !state.isChecked;
		},
		resetSingleData: (state) => {
			state.singleData = {};
		},
	},
	extraReducers: {
		[getAllStoreItems.pending]: (state) => {
			if (state.data.length <= 0) {
				state.loading = true;
			}
		},
		[getAllStoreItems.fulfilled]: (state, action) => {
			state.error = false;
			state.data = action.payload;
			state.loading = false;
		},
		[getAllStoreItems.rejected]: (state, action) => {
			state.error = true;
			state.message = action.payload;
			state.loading = false;
		},

		[getSingleStore.pending]: (state) => {
			state.loading = true;
		},
		[getSingleStore.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
			state.singleData = payload;
		},
		[getSingleStore.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},

		[createStoreItem.pending]: (state) => {
			state.loading = true;
		},
		[createStoreItem.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
		},
		[createStoreItem.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},

		[updateStoreDetails.pending]: (state) => {
			state.loading = true;
		},
		[updateStoreDetails.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
		},
		[updateStoreDetails.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},

		[deleteStoreItem.pending]: (state) => {
			state.loading = true;
		},
		[deleteStoreItem.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
		},
		[deleteStoreItem.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},
	},
});

export const { resetSingleData } = slice.actions;
export default slice.reducer;
