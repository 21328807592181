import AuthAPI from "./authInstance";

export const getListCodes = async () => {
	const response = await AuthAPI.get(`/admin/bulk/list`);
	return response;
};

export const generateBulkCodes = async (data) => {
	const response = await AuthAPI.post(`admin/bulk/generate`, data);
	return response.data;
};

export const bulkCodeService = {
	getListCodes,
	generateBulkCodes,
};
