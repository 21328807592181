import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sensitivityCheckerService } from '../services/sensitivityCheckerService'

export const analyzeText = createAsyncThunk(
  'sensitivity-checker/text',
  async (data, { rejectWithValue }) => {
    try {
      const response = await sensitivityCheckerService.analyzeText(data)
      return response
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'sensitivity-tracker',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = []
    },
  },
  extraReducers: {
    [analyzeText.pending]: (state) => {
      state.loading = true
    },
    [analyzeText.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [analyzeText.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
