import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/course/quiz/list`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/course/quiz/show/${data}`)
  return response.data
}

const createQuiz = async (data) => {
  const response = await AuthAPI.post(`/admin/course/quiz/create`, data)
  return response.data
}

const editQuiz = async (data) => {
  const response = await AuthAPI.post(`/admin/course/quiz/update`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/subject/delete/${data}`)
  return response.data
}

export const quizService = {
  getAll,
  getOne,
  createQuiz,
  editQuiz,
  deleteOne,
}
