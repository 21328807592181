import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bulkCodeService } from "../services/bulkCode";

export const getAllBulkCodes = createAsyncThunk(
	"bulk/codes/getAll",
	async (_, { rejectWithValue }) => {
		try {
			const response = await bulkCodeService.getListCodes();
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const generateBulkCodes = createAsyncThunk(
	"bulk/code/generate",
	async (data, { rejectWithValue }) => {
		try {
			const response = await bulkCodeService.generateBulkCodes(data);
			return response;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

const initialState = {
	data: [],
	singleData: {},
	loading: false,
	error: false,
	message: "",
};

const slice = createSlice({
	name: "bulkcodes",
	initialState,
	reducers: {},
	extraReducers: {
		[getAllBulkCodes.pending]: (state) => {
			if (state.data.length <= 0) {
				state.loading = true;
			}
		},
		[getAllBulkCodes.fulfilled]: (state, action) => {
			state.error = false;
			state.data = action.payload;
			state.loading = false;
		},
		[getAllBulkCodes.rejected]: (state, action) => {
			state.error = true;
			state.message = action.payload;
			state.loading = false;
		},

		[generateBulkCodes.pending]: (state) => {
			state.loading = true;
		},
		[generateBulkCodes.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
			//
		},
		[generateBulkCodes.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},
	},
});

export default slice.reducer;
