import AuthAPI from './authInstance'

const getAll = async () => {
  const response = await AuthAPI.get(`/portal/list-code-types`)
  return response
}

const getUsedCodes  = async () => {
  const response = await AuthAPI.get(`/admin/codes/get-used-codes`)
  return response
}

const generateCodes  = async (data) => {
  const response = await AuthAPI.post(`/user/codes/generate`, data)
  return response.data
}

const createCodeType  = async (data) => {
  const response = await AuthAPI.post(`/admin/codes/code-types`, data)
  return response.data
}

const editCodeType  = async (data) => {
  const response = await AuthAPI.post(`/admin/codes/edit-code-type`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/codes/delete-code-type`, data)
  return response.data
}


const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/user/show/${data}`)
  return response.data
}

export const codeService = {
  getAll,
  getOne,
  getUsedCodes,
  generateCodes,
  createCodeType,
  editCodeType,
  deleteOne
}
