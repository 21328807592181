import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { codeService } from '../services/codeService'


export const getUsedCodesByUser = createAsyncThunk('code/getUsedCodes', async (_, { rejectWithValue }) => {
    try {
      const response = await codeService.getUsedCodes()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  })

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'codes_by_user',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {


    [getUsedCodesByUser.pending]: (state) => {
        if (state.data.length <= 0) {
          state.loading = true
        }
      },
      [getUsedCodesByUser.fulfilled]: (state, action) => {
        state.error = false
        state.data = action.payload
        state.loading = false
      },
      [getUsedCodesByUser.rejected]: (state, action) => {
        state.error = true
        state.message = action.payload
        state.loading = false
      },



  },
})



export const { resetSingleData } = slice.actions
export default slice.reducer
