import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/books/list`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/company/show/${data}`)
  return response.data
}

const createBook = async (data) => {
  const response = await AuthAPI.post(`/admin/books/create-upload`, data)
  return response.data
}

const editBook = async (data) => {
  const response = await AuthAPI.put(`/admin/books/update/${data.id}`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/books/delete/${data}`)
  return response.data
}

export const bookService = {
  getAll,
  getOne,
  createBook,
  editBook,
  deleteOne,
}
