import AuthAPI from "./authInstance";

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/feed/all`);
  return response.data;
};

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/job/show/${data}`);
  return response.data;
};

const createPoll = async (data) => {
  const response = await AuthAPI.post(`/admin/feed/polls`, data);
  return response.data;
};

const createPost = async (data) => {
  const response = await AuthAPI.post(`/admin/feed/posts`, data);
  return response.data;
};

const createNotification = async (data) => {
  const response = await AuthAPI.post(`/admin/feed/notifications`, data);
  return response.data;
};

const createPhotoGallery = async (data) => {
  const response = await AuthAPI.post(`/admin/feed/photos`, data);
  return response.data;
};

const deleteOne = async (id) => {
  const response = await AuthAPI.delete(`/admin/feed/delete/${id}`)
  return response.data
}

export const feedService = {
  getAll,
  getOne,
  createPoll,
  createPost,
  createNotification,
  createPhotoGallery,
  deleteOne
};
