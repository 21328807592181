import AuthAPI from "./authInstance";

const getAllStoreItems = async (data) => {
	const response = await AuthAPI.get(`admin/bookstore/list`);
	return response.data;
};

const getSingleStore = async (data) => {
	const response = await AuthAPI.get(`/admin/bookstore/book/${data}`);
	return response;
};

const createStoreItem = async (data) => {
	const response = await AuthAPI.post(`admin/bookstore/create-book`, data);
	return response.data;
};

const updateStoreDetails = async (data) => {
	const response = await AuthAPI.post(
		`/admin/bookstore/update-book/${data.get("id")}`,
		data
	);
	return response.data;
};

const deleteStoreItem = async (data) => {
	const response = await AuthAPI.delete(`/admin/bookstore/delete-book/${data}`);
	return response.data;
};


export const storeService = {
	getAllStoreItems,
	getSingleStore,
	updateStoreDetails,
	deleteStoreItem,
	createStoreItem,
};
