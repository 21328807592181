import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/plans/list`)
  return response.data
}

const getSubscriptions = async (data) => {
  const response = await AuthAPI.get(`/admin/plans/show-subcriptions`)
  return response.data
}


const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/plans/get-plan/${data}`)
  return response.data
}

const createPlan = async (data) => {
  const response = await AuthAPI.post(`/admin/plans/create`, data)
  return response.data
}

const editPlan = async (data) => {
  const response = await AuthAPI.put(`/admin/plans/update-plan/${data.id}`, data)
  return response.data
}

const hideShowPlan = async (data) => {
  const response = await AuthAPI.put(`/admin/plans/hide-plan/${data}`)
  return response.data
}

export const planService = {
  getAll,
  getSubscriptions,
  getOne,
  createPlan,
  editPlan,
  hideShowPlan,
}
