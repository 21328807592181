import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { codeService } from '../services/codeService'

export const getAllCodes = createAsyncThunk('code/getAll', async (_, { rejectWithValue }) => {
  try {
    const response = await codeService.getAll()
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createCode = createAsyncThunk(
  'code/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await codeService.createCodeType(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const editCode = createAsyncThunk(
  'code/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await codeService.editCodeType(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteCode = createAsyncThunk(
  'code/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await codeService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)


export const getOneCode = createAsyncThunk('user/getOne', async (data, { rejectWithValue }) => {
  try {
    const response = await codeService.getOne(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})


const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'all_codes',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllCodes.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllCodes.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllCodes.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [createCode.pending]: (state) => {
      state.loading = true
    },
    [createCode.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createCode.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  
    [editCode.pending]: (state) => {
      state.loading = true
    },
    [editCode.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editCode.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteCode.pending]: (state) => {
      state.loading = true
    },
    [deleteCode.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteCode.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [getOneCode.pending]: (state) => {
      state.loading = true
    },
    [getOneCode.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneCode.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

  },
})



export const { resetSingleData } = slice.actions
export default slice.reducer
