import AuthAPI from './authInstance'

const getAll = async () => {
  const response = await AuthAPI.get(`/admin/school/list`)
  return response.data
}

const addSchool = async (data) => {
  const response = await AuthAPI.post(`/admin/school/add`, data)
  return response.data
}

export const schoolService = {
  getAll,
  addSchool,
}
